define("discourse/plugins/discourse-password-expiry/discourse/connectors/discovery-list-container-top/password-expiry-warning", ["exports", "@glimmer/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  }
  _exports.default = _default;
});